import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { forwardRef, useState, useEffect, useCallback, useMemo } from 'react';
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarContainer, gridFilteredSortedRowIdsSelector, gridVisibleSortedRowIdsSelector, useGridApiContext, GridFooter, GridFooterContainer, getGridNumericOperators, DataGrid, GridRowsProp, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import algosdk from 'algosdk';  // eslint-disable-line 
import CopyButton from './components/CopyButton.jsx';
import MuiLink from '@mui/material/Link';
import { createSvgIcon } from '@mui/material/utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Slide from '@mui/material/Slide';
import { BrowserRouter, Routes, Route, useParams, useNavigate, useLocation, Outlet } from "react-router-dom";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { usePageVisibility } from 'react-page-visibility';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import { HashLink as RouterLink } from 'react-router-hash-link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import donateImage from './donate.png';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import promilol from 'promilol';
import performNFDLookups from './nfd.js';

const algod = new algosdk.Algodv2('',  'https://mainnet-api.algonode.cloud', 443);

export function Link({ href, children, ...props }) {
  const component = href.startsWith('http') || href.startsWith('mailto') ? { target: "_blank", href: href } : { component: RouterLink, to: href };
 return <MuiLink {...component} {...props}>{children}</MuiLink>
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: green[500],
    },
    background: {
      default: "#ddd",
    }
  },
  typography: {
    fontFamily: 'monospace',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.3rem',
    },
  },
  button: {
    fontFamily: 'monospace',
  }
});

theme = responsiveFontSizes(theme);

function createAddressURL(address) {
  return window.location.origin + '/address/' + address;
}

function TextIconButton({children, onClick}) {
  return <IconButton onClick={onClick} size="small" sx={{ mb: '-6px', mt: '-6px' }}>{children}</IconButton>
}

async function sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)) }

const abortedError = 'Aborted. Incomplete results.';

let limit = 10_000;

async function lookup({indexer, setResults, setNN, target, attempts = 1, abort, nn, old_err}) {
  if (abort.signal.aborted) {
    setNN(nn);
    throw new Error(abortedError);
  }
  let query = indexer.searchForTransactions()
    .address(target)
    .limit(Math.floor(limit / attempts));
  if (nn)
    query = query.nextToken(nn);
  let res;
  try {
    console.log(Date.now(), "querying", target, nn);
    res = await query.do();
  } catch(e) {
    const message = e.response?.body?.message ?? e.response?.body ?? e.message;
    console.error(message);
    const sleepfor = Math.pow(attempts, 2) * 2000;
    console.error('sleeping for', sleepfor/1000);
    await sleep(sleepfor);
    if (message.includes('invalid input'))
      throw new Error(e);
    if (attempts > 2 && limit === 10_000)
      limit = 5_000;
    if (attempts > 4 && e.message == old_err) {
      console.error('too many errors, quiting');
      setNN(nn);
      throw new Error(e);
      // TODO resume
    }
    return lookup({ setResults, indexer, target, attempts: attempts+1, abort, nn, old_err: e.message, setNN });
  }
  setResults(res.transactions);
  if (res['next-token']) {
    nn = res['next-token'];
    setNN(nn);
    if (abort.signal.aborted)
      throw new Error(abortedError);
    await sleep(1000);
    return lookup({ setResults, indexer, target, attempts: 1, abort, nn, setNN });
  }
}

function findPayTxns(address, transaction) {
  const { id, "payment-transaction": ptxn, "confirmed-round": round, "round-time": rt, sender, "inner-txns": itxns } = transaction
  const ptxns = [];
  if (ptxn) {
    const { receiver, amount, "close-amount": camount, "close-remainder-to": creceiver } = ptxn;
    if (sender === address && amount) 
      ptxns.push({ id, sender, receiver, amount, rt });
    else if (receiver === address && amount)
      ptxns.push({ id, sender, receiver, amount, rt });
    if ((creceiver === address && camount) || (sender === address && camount)) {
      ptxns.push({ id, sender, receiver: creceiver, amount: camount, rt });
    }
  }
  if (itxns) {
    const itxnp = itxns.flatMap(itxn => findPayTxns(address, itxn)).map(t => (t.r = round, t.rt = rt, t.id = id, t))
    ptxns.push(...itxnp);
  }
  return ptxns;
}

async function checkEscrow(address, network="mainnet") {
  const first = address.slice(0, 3).toUpperCase();
  const resp = await fetch(`https://d13co.github.io/app-addrs/data/${network}/data/${first}.json`);
  const data = await resp.json();
  if (data[address]) {
    return data[address];
  }
}

async function lookupNFDs(addrs) {
  const resolved = await performNFDLookups(addrs);
  for(const [addr, nfd] of Object.entries(resolved)) {
    addressBook[addr] = nfd;
  }
  const resolvedAddrs = Object.keys(resolved);
  const remaining = addrs.filter(addr => !resolvedAddrs.includes(addr));
  // console.log('resolved', resolvedAddrs.length, 'remain', remaining.length, 'total', addrs.length);
  return remaining;
}

async function lookupAddresses(addrs, abortSignal) {
  const results = await promilol([addrs], lookupNFDs, { concurrency: 1, abortSignal, returnResults: true, globalConcurrency: 1 });
  const remaining = results.reduce((out, {value}) => out.concat(value), []);
  await promilol(remaining, lookupAddress, { concurrency: 20, abortSignal, globalConcurrency: 20 });
}

const { hostname } = window.location;
const isAlgo = hostname.endsWith('algo.surf') || hostname.endsWith('localhost');

async function lookupAddress(addr) {
  try {
    let account;
    if (isAlgo) {
      account = await algod.accountInformation(addr).do();
      if (account['auth-addr'] == 'XSKED5VKZZCSYNDWXZJI65JM2HP7HZFJWCOBIMOONKHTK5UVKENBNVDEYM') {
        const idxs = account['assets'].map(({"asset-id": aid}) => aid).reverse();
        for(const idx of idxs) {
          const { params } = await algod.getAssetByID(idx).do();
          if (params.name.startsWith('TinymanPool2.0 ')) {
            const lpTokenName = params.name.split(" ").slice(1).join(" ").replace("-", "/");
            addressBook[addr] = `Tinyman 2.0 ${lpTokenName}`;
            return;
          }
        }
        addressBook[addr] = `TinymanPool2.0 Unidentified`;
      }
    }
    const isEscrow = await checkEscrow(addr);
    if (isEscrow) {
      addressBook[addr] = `App ${isEscrow}`;
      if (account && account['total-created-assets'] > 0) {
        const { "created-assets": cAss } = account;
        if (cAss[0] && cAss[0].params?.name?.endsWith('PACT LP Token')) {
          const lpTokenName = cAss[0].params.name.replace(' PACT LP Token', '');
          let name = `PACT ${lpTokenName}`;
          const data = await algod.getApplicationByID(isEscrow).do();
          const state = data.params['global-state'];
          const feesBps = state.find(({key}) => key === "RkVFX0JQUw==");
          if (feesBps) {
            name += ` ${feesBps.value.uint / 100}%`;
          } else {
            name += ` 0.3%`;
          }
          addressBook[addr] = name;
          return;
        }
      }
    }
  } catch(e) {
    console.error("lookupAddressEscrow", e);
  }
}

async function loadTxns({ address, indexer, setResults, setError, setLoading, abort, setAbort, nn, setNN }) {
  const lookupAddressTypesPromises = [];
  let i=0;
  let totalAddresses = 0;
  function acceptResults(transactions) {
    const results = {};
    for(const transaction of transactions) {
      const ptxns = findPayTxns(address, transaction);
      for(const ptxn of ptxns) {
        const {id, sender, receiver, amount, r, rt} = ptxn;
        let other;
        let direction;
        if (sender === receiver) {
          console.log("not logging self send");
          continue;
        }
        if (sender !== address && receiver !== address) {
          console.error("Got ptxn without address as party", JSON.stringify(ptxn));
          continue;
        } else if (sender === address) {
          other = receiver;
          direction = "o";
        } else if (receiver === address) {
          other = sender;
          direction = "i";
        }
        results[other] = results[other] ?? { i: [], o: [], it: 0, ot: 0 }
        results[other][direction].push({a: amount, id: id, r: r, rt: rt});
        results[other][`${direction}t`] += amount;
      }
      i++
    }
    setLoading(`Loading... ${renderNum(i, 0)} transactions`);
    // console.log('got results', results);
    let r = 0;
    setResults(prevResults => {
      // console.log('prevResults', prevResults, r++);
      let theNewOnes = [];
      prevResults = prevResults ?? {};
      for(const [ad, { i, o, it, ot }] of Object.entries(results)) {
        let record = prevResults[ad];
        const isNew = !record;
        if (isNew) {
          theNewOnes.push(ad);
          record = { ad, tc: 0, f: Infinity, l: -Infinity, i: [], o: [], it: 0, ot: 0, d: 0 };
        }
        record.tc += i.length + o.length;
        record.i.push(...i);
        record.o.push(...o);
        const [f, l] = i.concat(o).reduce((out, {rt}) => {
          rt *= 1_000;
          if (out[0] > rt)
            out[0]=rt
          if (out[1] < rt)
            out[1]=rt
          return out;
        }, [+record.f, +record.l]);
        record.f = new Date(f);
        record.l = new Date(l);
        record.it += it / 1_000_000;
        record.ot += ot / 1_000_000;
        record.d = record.it - record.ot;
        if (isNew) {
          prevResults[ad] = record;
        }
      }
      if (theNewOnes.length) {
        totalAddresses += theNewOnes.length;
        lookupAddressTypesPromises.push(
          lookupAddresses(theNewOnes, abort)
        );
      }
      return prevResults;
    });
  }
  try {
    console.time('lookup');
    console.time('lookupAddressTypes');
    await lookup({ indexer, setResults: acceptResults, target: address, attempts: 1, abort, setNN, nn });
    console.timeEnd('lookup');
    setLoading(`Analyzing... ${totalAddresses.toLocaleString()} addresses`);
    await Promise.all(lookupAddressTypesPromises);
    console.timeEnd('lookupAddressTypes');
    setLoading(false);
    setAbort();
  } catch(e) {
    setLoading(false);
    setAbort();
    setError(e.message);
  }
}

function Small({ sx, children }) {
  return <Typography sx={{fontSize: '0.75rem', ...sx}}>{children}</Typography>
}

function makeSummaryData(results) {
  return results.reduce((sum, { ad, it, ot, i, o, f, l }) => {
    sum.total += i.length + o.length;
    sum.addresses += 1;
    sum.inflows += it
    sum.outflows += ot
    sum.delta += it - ot;
    sum.f = sum.f < f ? sum.f : f;
    sum.l = sum.l > l ? sum.l : l;
    return sum;
  }, { addresses: 0, total: 0, inflows: 0, outflows: 0, delta: 0, f: new Date('2100-01-01'), l: new Date('1970-01-01') });
}

function Summary({ address, results, sx }) {
  const { addresses, total, inflows, outflows, delta, f, l } = makeSummaryData(Object.values(results));
  let name = addressBook && addressBook[address];
  const { tokenSymbol: ts, makeAccountLink, } = useConfig();
  const sep = '·'
  return <Small sx={sx}><Link href={makeAccountLink(address)}>Explore</Link>{ name ? ` ${sep} Label: ${name} ` : ""} {sep} Addresses:&nbsp;{renderNum(addresses)} {sep} Txns:&nbsp;{renderNum(total)} {sep} In:&nbsp;{ts}&nbsp;{renderNum(inflows)} {sep} Out:&nbsp;{ts}&nbsp;{renderNum(outflows)} {sep} Net:&nbsp;{ts}&nbsp;{renderNum(delta)} {sep} Dates:&nbsp;{f.toLocaleDateString()} - {l.toLocaleDateString()}</Small>
}

function renderNum(num, precision = 2) {
  return (num).toLocaleString(undefined, { maximumFractionDigits: precision });
}

function renderNumber({ value: num }) {
  if (num > 0.01 || num < 0.01)
    return renderNum(num)
  return num;
}

function vf({ value }) {
  return value;
}

const gteOp = {
  ...getGridNumericOperators()[0],
  label: 'abs(>)',
  value: 'absgte',
  getApplyFilterFn: (filterItem, column) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    return (params): boolean => {
      return Math.abs(Number(params.value)) > Math.abs(Number(filterItem.value));
    };
  },
};

const lteOp = {
  ...getGridNumericOperators()[0],
  label: 'abs(<)',
  value: 'abslte',
  getApplyFilterFn: (filterItem, column) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    return (params): boolean => {
      return Math.abs(Number(params.value)) < Math.abs(Number(filterItem.value));
    };
  },
};

const filterOperators = [
  ...getGridNumericOperators(),
]
filterOperators.splice(2, 0, gteOp);
filterOperators.splice(5, 0, lteOp);

const numOperators = getGridNumericOperators();

function makeColumns(config) {
  const { tokenSymbol: ts } = config;
  return [
    { field: 'ad', headerName: 'Address', minWidth: 80, flex: 0.4, hidden: true, renderCell: renderAddress, },
    { field: 'adl', headerName: 'Address Label', minWidth: 80, flex: 0.4, hidden: true, valueGetter: renderAddressLabel, },
    { field: 'tc', headerName: 'Txns', flex: 1, minWidth: 20, maxWidth: 65, filterOperators: getGridNumericOperators(), },
    { field: 'f', headerName: 'First Transacted', flex: 0.5, type: 'dateTime', maxWidth: 180, },
    { field: 'l', headerName: 'Last Transacted', flex: 0.5, type: 'dateTime', maxWidth: 180, },
    { field: 'it', headerName: `${ts} In`, flex: 0.5, minWidth: 110, maxWidth: 140, renderCell: renderNumber, valueFormatter: vf, filterOperators: numOperators, },
    { field: 'ot', headerName: `${ts} Out`,flex: 0.5,  minWidth: 110, maxWidth: 140, renderCell: renderNumber, valueFormatter: vf, filterOperators: numOperators, },
    { field: 'd', headerName: `${ts} Net`, flex: 0.5, minWidth: 110, maxWidth: 140, renderCell: renderNumber, valueFormatter: vf, filterOperators, },
    ];
}

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

const getFilteredRows = ({ apiRef }) => gridFilteredSortedRowIdsSelector(apiRef);

const CustomToolbar = ({ selected, showDialog, csvOptions }) => {
  const apiRef = useGridApiContext();
  const handleExport = (options) => apiRef.current.exportDataAsCsv({ ...csvOptions, ...options });

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const txns = selected ? selected.map(addr => apiRef.current.getRow(addr).tc).reduce((sum, c) => sum+(c??0), 0) : null;

  const launch = useCallback(() => {
    if (selected.length > 3) {
      const sure = window.confirm(`This will open ${selected.length} windows. Are you sure?`);
      if (!sure)
        return
    }
    for(const address of selected) {
      window.open(createAddressURL(address), '_blank');
    }
  }, [selected]);

  return (
    <GridToolbarContainer sx={{flexDirection: {xs: "column-reverse", md: "row"}, justifyContent: 'flex-end'}}>
      { selected ? <div style={{flexGrow: 1, display: 'inline-flex', alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center'}}>
        { showDialog ? <Button variant="outlined" startIcon={<FormatListNumberedIcon />} size="small" onClick={() => showDialog(selected)}>SHOW {txns} TXNS</Button> : null }
        <Button sx={{ml: 1}} variant="outlined" startIcon={<AccountTreeIcon />} size="small" onClick={launch}>OPEN{selected.length > 1 ? " "+selected.length+" ":" " }FLOWS</Button>
        <Explore addresses={selected} sx={{display: { xs: 'none', sm: 'flex' }}}/>
      </div> : null }
      <div style={{flexGrow: 1, display: 'inline-flex', alignSelf: 'stretch', justifyContent: 'flex-end', alignItems: 'center'}}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Button
          {...buttonBaseProps}
          onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
        >
          Export
        </Button>
      </div>
    </GridToolbarContainer>
  );
};

function Explore({addresses, sx}) {
  const { makeAccountLink } = useConfig();
  const explore = useCallback(() => {
    if (addresses.length > 3) {
      const sure = window.confirm(`This will open ${addresses.length} windows. Are you sure?`);
      if (!sure)
        return
    }
    for(const address of addresses) {
      console.log(address, 'window', window.open(makeAccountLink(address), '_blank'));
    }
  }, [addresses]);

  return <Button sx={{ml: 1, ...sx}} startIcon={<FontDownloadIcon />} size="small" onClick={explore}>
    EXPLORE{addresses.length > 1 ? ` (${addresses.length})`:''}
  </Button>
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Results({results, loading, address, showDialog}) {
  const { width } = useWindowDimensions();

  const [sortModel, setSortModel] = useState([
    {
      field: 'l',
      sort: 'desc',
    },
  ]);

  const [selected, setSelected] = useState();

  const handleSelection = useCallback((selections) => {
    setSelected(selections?.length ? selections : false);
  }, []);
  
  const config = useConfig();
  const columns = useMemo(() => makeColumns(config), []);

  return <DataGrid
    checkboxSelection={true}
      initialState={{
          columns: {
            columnVisibilityModel: {
              f: false,
              adl: false,
              l: width > 550,
              d: width > 550,
          },
        },
      }}
    componentsProps={{ footer: { selected, showDialog, }, toolbar: { selected, showDialog, csvOptions: { allColumns: true, fileName: `flows-${address}` }, printOptions: { disableToolbarButton: true } } }}
      components={{
        Toolbar: CustomToolbar,
        LoadingOverlay: LinearProgress,
        Footer: CustomFooter,
      }}
      rows={Object.values(results)}
      columns={columns}
      getRowId={row => row.ad}
      density="compact"
      rowsPerPageOptions={[50,100,500]}
      loading={!!loading}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      onSelectionModelChange={handleSelection}
      hideFooterSelectedRowCount={true} 
      sx={{alignSelf: 'stretch', mt: 1, minHeight: '400px'}}
    />
}

function VFlex({ children, sx }) {
  return <HFlex sx={{...sx, flexDirection: 'column'}}>{children}</HFlex>;
}

function HFlex({ children, sx }) {
  const osx = { 
    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row",
    ...sx,
  }
  return <Box sx={osx}>{children}</Box>
}

function CustomFooter ({selected, showDialog}) {
  return (
    <GridFooterContainer sx={{width: 1, pl: 0, alignItems: { xs: 'flex-start', md: 'center' }, flexDirection: { xs: 'column', md: 'row' }}}>
      { selected?.length ? <HFlex sx={{mb: { xs: '-10px'}}}>
        <CopyButton label={`COPY ${selected.length === 1 ? snipAddress(selected[0], 6, false) : selected.length+" Addresses"}`} value={selected.join("\n")} edge="start" copyIcon={true}/> 
        <Explore addresses={selected} sx={{display: { xs: 'flex', sm: 'none' }}}/>
      </HFlex>
      : null }
      <GridFooter sx={{
        border: 'none', // To delete double border.
        flexGrow: 1,
        alignSelf: 'stretch',
        justifyContent: 'flex-end',
        mt: 0,
        }} />
    </GridFooterContainer>
  );
}

function renderAddressLabel ({ row }) {
  const { ad: address } = row;
  if (addressBook && addressBook[address]) {
    return addressBook[address];
  }
}

function renderAddress ({ value: address }) {
  if (addressBook && addressBook[address]) {
    return `${addressBook[address]} ${address.slice(0, 8)}..`;
  }
  return address;
}

function renderTx (id, { makeTxLink }) {
  return <><CopyButton noLabel={true} value={id} edge="start" copyIcon={true}/><Link target="_blank" href={makeTxLink(id)}>{snipAddress(id, 10)}</Link></> 
}

function makeColumnsDetails(config) {
  return [
    { field: 'ad', minWidth: 60, headerName: 'Address', flex: 0.5, renderCell: renderAddress, },
    { field: 'adl', headerName: 'Address Label', hidden: true, valueGetter: renderAddressLabel, },
    { field: 'id', minWidth: 40, headerName: 'Txn ID', flex: 0.5, renderCell: ({value: id}) => renderTx(id, config), },
    { field: 'rt', headerName: 'Date/Time', type: 'dateTime', minWidth: 100, maxWidth: 250, flex: 0.5, },
    { field: 'dir', headerName: 'Flow', type: 'string', minWidth: 50, maxWidth: 65, flex: 1 },
    { field: 'amt', headerName: 'Amount', minWidth: 80, maxWidth: 150, flex: 0.5, renderCell: renderNumber, valueFormatter: vf, filterOperators, },
  ];
}

function txToRows(data) {
  function r(ad, dir) {
    return ({a, id, rt, r}, i) => ({id, ad: ad, iid: id+"i"+i, amt: (dir === 'in' ? -1 : 1 ) *a/1_000_000, dir, rt: new Date(rt*1000)});
  }
  return data?.flatMap(({ad, i, o}) => i.map(r(ad, 'in')).concat(o.map(r(ad, 'out')))) ?? [];
  // const rows = [];
  // if (!data?.i)
  //   return [];
  // data.i.forEach(({a, id, r, rt}, i) => {
  //   rows.push();
  // });
  // data.o.forEach(({a, id, r, rt}, i) => {
  //   rows.push({id, ad: ad, iid: id+"o"+i, amt: a/1_000_000, dir: 'out', rt: new Date(rt*1000)});
  // });
  // return rows;
}

function DetailsDialog({ open, setOpen }) {
  const { address, address2, data } = open ?? {};
  const [sortModel, setSortModel] = useState([
    {
      field: 'rt',
      sort: 'desc',
    },
  ]);

  const multi = address2?.length > 1;

  const handleClose = () => {
    setOpen(false);
  };

  const rows = useMemo(() => {
    return txToRows(data);
  }, [data]);

  const { width, height } = useWindowDimensions();

  const fileName = `flows-${address}-${address2?.map(addr => snipAddress(addr, 8, false, false)).join('-')}`.slice(0, 140);

  const config = useConfig();
  const columnsDetails = useMemo(() => makeColumnsDetails(config), []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      fullScreen={height < 600 || width < 600}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
      }
      }}
    >
      <DialogTitle sx={{display: 'flex', pt: 1, pb: 1}}>
        <STypography>{address}</STypography>
        <Typography component="span" sx={{ml: 1, mr: 1}}> ⇄ </Typography>
        {address2?.length > 5 ? <STypography>{address2.length} Addresses</STypography> : address2?.map((address2, i, all) => <STypography key={`ad-${i}`}>{address2}</STypography>)}<STypography>&nbsp;&nbsp;</STypography>
        <IconButton sx={{position: 'absolute', top: 3, right: 4, }} size="small" edge="end" onClick={handleClose}><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '80vh', pb: 1}}>
        <DataGrid
          componentsProps={{ toolbar: { csvOptions: { fileName, allColumns: true, }, printOptions: { disableToolbarButton: true } } }}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ad: multi,
                  adl: false,
                  dir: width > 550,
              },
            },
          }}
          rows={rows}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          columns={columnsDetails}
          getRowId={row => row.iid}
          density="compact"
          rowsPerPageOptions={[50,100,500]}
          hideFooterSelectedRowCount={true}
          disableSelectionOnClick={true}
          sx={{alignSelf: 'stretch'}}
        />
      </DialogContent>
    </Dialog>
  );
}

function STypography({ children, ...props }) {
  return <Typography {...props} component="span" sx={{display: "inline-block"}} noWrap={true}>{children}</Typography>
}

function snipAddress(address, num=8, both=true, dots=true,) {
  return address ? address.slice(0, num) + (dots ? '..' : '') + (both ? address.slice(-num) : '') : '';
}

function setPageTitle(siteName, title) {
  document.title = siteName+" "+title;
}

const xsPad = {
  ml: { xs: 2 },
  mr: { xs: 2 },
}

async function loadAddressBook() {
  while(true) {
    try {
      const resp = await fetch('/address-book.json');
      const book = await resp.json();
      addressBook = book;
      console.log("Loaded address book");
      return;
    } catch(e) {
      console.error('Error loading address book', e.message);
      await sleep(2_000);
    }
  }
}

let addressBook

async function lookupPrefix(addr) {
  try {
    addr = addr.slice(0, 4).toUpperCase();
    const resp = await fetch(`https://addr-data.algo.surf/addresses/${addr}.json`);
    const json = await resp.json();
    return json;
  } catch(e) {
    console.error(e);
    return [];
  }
}

const StyledPopper = styled('div')(() => ({
  backgroundColor: lighten(theme.palette.background.default, 0.5),
}));

function AppAddress() {
  const [loading, setLoading] = useState(false);
  const focused = usePageVisibility();
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const { address = '' } = useParams();
  const [inputAddress, setInputAddress] = useState(address);
  const [abort, setAbort] = useState();
  const [results, setResults] = useState();
  const [error, setError] = useState('');
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [nn, setNN] = useState();
  const navigate = useNavigate();
  const [activeAddress, setActiveAddress] = useState();
  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const { indexer, tokenName, networkName, siteName, } = useConfig();

  const setAddress = (address) => {
    if (address)
      navigate(`/address/${address}`);
    else
      navigate(`/`);
  }

  useEffect(() => {
    if (!addressBook)
      loadAddressBook();
  }, []);

  const autocompleteNFD = useCallback((addr) => {
    (async() => {
      const url = `https://api.nf.domains/nfd/${addr?.toLowerCase()}?view=brief`;
      try {
        const resp = await fetch(url);
        const json = await resp.json();
        const results = [];
        const { caAlgo = [], unverifiedCaAlgo = []} = json;
        for(const address of caAlgo) {
          results.push(['verified', address]);
        }
        for(const address of unverifiedCaAlgo) {
          results.push(['unverified', address]);
        }
        setAutocompleteOptions(prev =>
          prev.filter(elem => elem && !elem.contains('.algo'))
            .concat(results.map(([status, address]) => `${addr} - ${status} - ${address}`))
        );
      } catch(e) {
        console.error(e);
      }
    })()
  }, []);

  const onChange = useCallback(({ target }) => {
    let { value: addr } = target ?? {};
    setInputAddress(addr);
    if (!addr) return;
    if (addr.toLowerCase().endsWith('.algo')) {
      autocompleteNFD(addr);
    } else {
      setAutocompleteOptions([]);
    }
    if (addr.length === 58 && !loading) {
      setNN();
      setAutocompleteOptions([]);
      setAddress(addr);
    }
  }, [loading]);

  useEffect(() => {
    if (focused && !hasBeenFocused)
      setHasBeenFocused(true);
  }, [hasBeenFocused, focused]);

  useEffect(() => {
    if (!hasBeenFocused) {
      console.log("not starting for not focused window");
      if (address)
        setPageTitle(siteName, "<Waiting>");
      return
    }
    console.log("effect address", address);
    if (address?.length === 58 && address !== activeAddress) {
      if (activeAddress !== address && abort) {
        return window.location.reload();
      }
      setInputAddress(address);
      setPageTitle(siteName, address);
      setTimeout(() => onSubmit(address), 2);
    } else if (!address?.length && (results || loading)) {
      window.location.reload();
    }
  }, [hasBeenFocused, address]);

  const onSubmit = useCallback((addr, nn) => {
    if (abort)
      abort.abort();
    setError('');
    setNN();
    if (!nn) {
      setResults();
    }
    const target = typeof addr === "string" ? addr : address;
    if (target.length === 58 || target.trim().length === 58) {
      const address = target.trim();
      setActiveAddress(target);
      setLoading('Loading...');
      const abort = new AbortController()
      setAbort(abort)
      console.log("loadTxns");
      loadTxns({ indexer, address: target.trim(), setResults, setError, setLoading, abort, setAbort, nn, setNN, });
    }
  }, [address, abort]);

  const cancel = useCallback(() => {
    setLoading("Aborting");
    abort.abort();
    setAbort();
  }, [abort]);

  const showDialog = useCallback((selected) => {
    const data = selected.map(addr => results[addr]);
    setDetailsOpen({address, address2: selected, data, });
  }, [address, results]);

  const onResume = useCallback(() => {
    if (nn) {
      onSubmit(address, nn);
    }
  }, [address, nn]);

  const onChange2 = useCallback((_, addr) => {
    console.log("selected");
    if (addr.toLowerCase().includes('.algo') && addr.includes('verified'))
      addr = addr.split(' - ')[2];
    onChange({target: { value: addr }});
  }, [onChange])

  return <>
    {!results ? <Typography align="center">Track {tokenName} inflow and outflows of any {networkName} address.</Typography> : null }
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={autocompleteOptions}
      sx={{ alignSelf: 'center', mt: 1, mb: 2, width: { xs: 0.98, md: 0.9, lg: 0.8 } }}
      value={inputAddress}
      onChange={onChange2}
      inputValue={inputAddress}
      onInputChange={onChange}
      ListboxComponent={StyledPopper}
      renderInput={(params) => 
        <TextField
          {...params}
          variant="outlined"
          fullWidth={true}
          autoFocus={true}
          disabled={!!loading}
          placeholder="Enter an Address or NFD"
          InputProps={{
            ...params.InputProps,
            endAdornment: <InputAdornment position="end">
              {loading ? <>
                <SettingsIcon sx={{animation: 'rotate 1s forwards infinite'}}/>
                <TextIconButton aria-label="Stop" color={"primary"} onClick={cancel} edge="end">
                  <CloseIcon />
                </TextIconButton>
              </> : <>
                <TextIconButton aria-label="Lookup Address" color={"primary"} onClick={onSubmit} edge="end">
                  <Search />
                </TextIconButton>
              </>}
            </InputAdornment>
          }}
        />
      }
    />
    { loading ? <Typography sx={{mb: 2}}>{loading}</Typography> : null }
    { error && !abort ? <HFlex>
      <Typography sx={{mb: 1}} color="error">{error}</Typography>
      { nn ? <Button onClick={onResume} size="small" color="primary" sx={{mt: "-8px"}}>RESUME</Button> : null }
    </HFlex>: null }
    { results ? <Summary address={address} results={results} sx={xsPad} /> : null }
    { !address ? <VFlex>
      <Link href="/docs">Documentation</Link>
      <Link href="/address-book" sx={{mb: 1}}>Address Book</Link>
      <HFlex sx={{alignItems: 'flex-start'}}>
        <Typography sx={{mr: 1}}>
          Examples:
        </Typography>
        <Typography>
          <Link href="/address/7K5TT4US7M3FM7L3XBJXSXLJGF2WCXPBV2YZJJO2FH46VCZOS3ICJ7E4QU">Gov Rewards P5</Link> <br />
          <Link href="/address/IVBHJFHZWPXRX2EA7AH7Y4UTTBO2AK73XI65OIXDEAEN7VO2IHWXKOKOVM">Kraken</Link><br/>
          <Link href="/address/ZVMOZVZJK64NEYDPUDGGC52NI6HOX2LUQVIWYCQTJ2DFXRGPL72C2BQYNM">OKEX</Link>
        </Typography>
      </HFlex>
    </VFlex> : null }
    { results ? <Results address={address} loading={loading} results={results} showDialog={showDialog} /> : null }
    <DetailsDialog open={detailsOpen} setOpen={setDetailsOpen} />
  </>;
}

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    console.log("location", location);
    const path = location.pathname + location.search + location.hash;
    try{
      window.goatcounter?.count({ path });
    } catch(e) {
      console.log("goatcounter count error", e.message);
    }
  }, [location]);
}

const algoConfig = {
  siteName: 'flow.algo.surf',
  indexer: new algosdk.Indexer("",  "https://mainnet-idx.algonode.cloud", 443),
  tokenName: 'ALGO',
  networkName: 'Algorand',
  fullNetworkName: 'Algorand Mainnet',
  tokenSymbol: 'Ⱥ',
  explorerName: 'Algo.surf',
  makeTxLink: id => `https://algo.surf/transaction/${id}`,
  makeAccountLink: addr => `https://algo.surf/account/${addr}`,
};

const voiConfig = {
  siteName: 'flow.voi.observer',
  indexer: new algosdk.Indexer("",  "https://testnet-idx.voi.nodly.io", 443),
  tokenName: 'VOI',
  networkName: 'Voi Testnet',
  fullNetworkName: 'Voi Testnet',
  tokenSymbol: 'V',
  explorerName: 'Voi Observer',
  makeTxLink: id => `https://voi.observer/explorer/transaction/${id}`,
  makeAccountLink: addr => `https://voi.observer/explorer/account/${addr}`,
};

function useConfig() {
  const config = useMemo(() => {
    const { hostname } = window.location;

    if (hostname.endsWith('algo.surf')) {
      return algoConfig;
    } else if (hostname.endsWith('localhost')) {
      return algoConfig;
      return voiConfig;
    } else if (hostname.endsWith('voi.observer')) {
      return voiConfig;
    } else {
      throw new Error('Could not determine site configuration from domain');
    }
  }, [window.location.hostname]);

  return config;
}

function Layout() {
  // usePageViews();
  const { siteName } = useConfig();
  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container id="outer-container" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minWidth: { xs: '100%', md: '960px', lg: '960px'}, pr: { xs: 0 }, pl: { xs: 0 } }}>
      <Typography align="center" gutterBottom={true} variant="h1" sx={{mt: 2, letterSpacing: '0.6rem'}}><Link color="text.primary" href="/"><AccountTreeIcon/></Link> {siteName} </Typography>
      <Outlet />
      <HFlex sx={{mb:1}}><Small>By <Link target="_blank" href="https://D13.co">D13.co</Link>. Powered by <Link target="_blank" href="https://algonode.io/">algonode.io</Link>. <Link target="_blank" href="https://twitter.com/algo_surf">Twitter</Link>. <Link target="_blank" href="/docs#contribute"><strong>Contribute!</strong></Link></Small></HFlex>

    </Container>
  </ThemeProvider>;
}

function H2({ children }){
  return <Typography variant="h2" sx={{mt: 3}} gutterBottom={true}>{children}</Typography>
}

function H3({ children, ...props }){
  return <Typography {...props} variant="h3" gutterBottom={true} sx={{mt: 2}}>{children}</Typography>
}

function H4({ children }){
  return <Typography variant="h4" gutterBottom={true} sx={{mt: 1}}>{children}</Typography>
}

function T(props) {
  return <Typography gutterBottom={true} {...props} />
}

function AddressBook() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      if (!addressBook) {
        await loadAddressBook();
        setCount(count + 1);
      }
    })()
  }, []);

  const [selected, setSelected] = useState();

  const handleSelection = useCallback((selections) => {
    setSelected(selections?.length ? selections : false);
  }, []);

  return <VFlex sx={{alignItems: 'flex-start', flexGrow: 1, pl: 2, pr: 2, alignSelf: 'stretch'}}>
    <H2>Address Book</H2>
    <T>Identified a new Exchange address? Spotted an inaccuracy? <Link target="_blank" href="https://forms.gle/RNdnxQPvp26ExTZq8">Submit</Link> a change request!</T>
    <DataGrid
      checkboxSelection={true}
      components={{
        Toolbar: CustomToolbar,
        LoadingOverlay: LinearProgress,
        Footer: CustomFooter,
      }}
      componentsProps={{ footer: { selected, }, toolbar: { selected, csvOptions: { allColumns: true, fileName: `address-book` }, printOptions: { disableToolbarButton: true } } }}
      rows={Object.entries(addressBook ?? {}).map(([address, label]) => ({ address, label }))}
      columns={[
        { field: 'address', headerName: 'Address', minWidth: 80, flex: 0.5, },
        { field: 'label', headerName: 'Label', minWidth: 80, flex: 0.5, },
      ]}
      getRowId={row => row.address}
      density="compact"
      rowsPerPageOptions={[50,100,500]}
      loading={!addressBook}
      onSelectionModelChange={handleSelection}
      hideFooterSelectedRowCount={true}
      sx={{alignSelf: 'stretch', mt: 1, minHeight: '400px'}}
    />
  </VFlex>
}

function Strikethrough({ children }) {
  return <span style={{textDecoration: 'line-through'}}>{children}</span>
}

function Docs() {
  const { siteName, tokenSymbol: ts, networkName, fullNetworkName, tokenName, explorerName, } = useConfig();

  return <VFlex sx={{alignItems: 'flex-start', pl: 2, pr: 2, alignSelf: 'stretch'}}>
    <H2>Documentation</H2>
    <T>{siteName} is a tool to track & aggregate {tokenName} movements of {networkName} accounts.</T>
    <H3>Searching</H3>
    <T>Search for any {fullNetworkName} address ("target address") to populate the Data table.</T>
    <T>We load 20,000 transactions at a time from the <Link target="_blank" href="https://algonode.io">algonode.io</Link> indexer and parse the supported transactions to calculate aggregate {tokenName} flows. For accounts with large volume, the information will be updated as it becomes available.</T>
    <T>Transactions are loaded from most recent to oldest. You can interrupt loading with the <CloseIcon /> icon. If an error occurs, or you intentionally aborted, you should be able to resume from where you left off with the <Button>RESUME</Button> button.</T>
    <H3>Data Table</H3>
    <T>The Data table supports the following fields:</T>
    <T>- Address: The address that interacted with the target address.</T>
    <T>- Txns: Number of ${tokenName} transactions between the target address and each row's address.</T>
    <T>- First Transacted: Date/Time of first transaction between the addresses. Hidden by default.</T>
    <T>- Last Transacted: Date/Time of last transaction between the addresses. Hidden by default on mobile.</T>
    <T>- {ts} In: Aggregate inflows from row address to target address.</T>
    <T>- {ts} Out: Aggregate outflows from row address to target address.</T>
    <T>- {ts} Net: Net between outflows and inflows. Hidden by default on mobile.</T>
    <H4>Table Options: Sorting</H4>
    <T>Click on a column header to toggle sorting by that column. You may experience delays when sorting large result sets.</T>
    <H4>Table Options: Columns</H4>
    <T>Certain columns are hidden by default, depending on your device. You can show or hide columns from the <Button>COLUMNS</Button> button on the top right of the Data Table.</T>
    <H4>Table Options: Filters</H4>
    <T>You can filter the table by any field. Click the <Button>FILTERS</Button> button on the top right or the <MoreVertIcon /> dots icon that appears when you hover a column header.</T>
    <T>The Date filters require both the date and the time to be filled in before they take effect.</T>
    <T>The "{ts} Net" column supports filtering by absolute values: "abs(&lt;)" and "abs(&gt;)".</T>
    <H4>Table Options: Export</H4>
    <T>You can export the table to a CSV by clicking the <Button>EXPORT</Button> button on the top right of the Data Table. Any selected filters <strong>will</strong> be applied. All available pages will be exported.</T>
    <H3>Address selection & actions</H3>
    <T>Click on any row to select it. Click again to de-select it. You can select or de-select all rows from the checkbox at the top left of the table, next to the "Address" column header.</T>
    <T>When you have active selections, the following actions will be displayed above and below the Data Table.</T>
    <H4>Table Actions: Show Transactions</H4>
    <T><Button variant="outlined">SHOW 3 TXNS</Button> This button will show a table with all payment related transactions between the target account and the selected accounts. You can sort, filter and export from that table as well.</T>
    <H4>Table Actions: Open Flows</H4>
    <T><Button variant="outlined">OPEN 3 FLOWS</Button> This button open a new tab on {siteName} for each selected address. Make sure to disable your popup blocker if you are trying to open multiple tabs. <strong>To avoid rate limiting and overwhelming indexers, you must visit each opened tab before it starts loading data.</strong></T>
    <H4>Table Actions: Explore</H4>
    <T><Button>EXPLORE (3)</Button> This button open the selected accounts' pages on {explorerName}.</T>
    <H4>Table Actions: Copy Addresses</H4>
    <T><Button>COPY 3 ADDRESSES</Button> This button will copy the selected addresses to your clipboard.</T>
    <H3>Supported transactions</H3>
    <T>We support the following ways {tokenName} can flow in and out of an account:</T>
    <T>- Payment transactions</T>
    <T>- Close remainder transactions</T>
    <T>- Inner transactions</T>
    <H4>Not Supported</H4>
    <T>The following ways of accruing and spending {tokenName} are not supported:</T>
    <T>- Rewards accumulation</T>
    <T>- Transaction fees</T>
    <T>As such, the "{ts} Net" value will not line up with the remaining balance of an account perfectly. Due to the now-deprecated rewards accumulation, some <Link href="/address/YX5KZSZT27L7WZAW7TNONVDZHQQAURJKT4BPRS364KTH2DGMEKLLFOPK3U">older</Link> {networkName} accounts may display a net negative outflow.</T>
    <H3>Address Book</H3>
    <T>We have collected a list of 500+ known organizational addresses, such as {networkName} inc, {networkName} Foundation and CEX addresses. Where possible, we use the same labels as {explorerName} to avoid confusion. We have identified several previously-unlabelled addresses through chain analysis (e.g. Coinbase 2.)</T>
    <T>The Address field of identified addresses will display the address book label when viewed on the site. CSV exports do not have address book labels (for now.)</T>
    <T>You can view the full address book <Link href="/address-book">here</Link> and suggest additions or changes <Link target="_blank" href="https://forms.gle/RNdnxQPvp26ExTZq8">here</Link>.</T>
    <H3>Roadmap</H3>
    <T>Some possible improvements and features we have in mind:</T>
    <T><Strikethrough>- Address Book: Display known address labels, e.g. CEX, Foundation</Strikethrough></T>
    <T><Strikethrough>- NFDomains support</Strikethrough></T>
    <T>- Visualisations/Sankey graphs</T>
    <T>- ASA Support</T>
    <T>- Dark mode</T>
    <T><strong>If you want to see a specific feature sooner rather than later, you can contribute to the developer tip jar with a note outlining your feature request.</strong></T>
    <Contribute />
    <T sx={{mt: 1}}>&nbsp;</T>
  </VFlex>
}

function Contribute() {
  const { siteName } = useConfig();
  return <>
    <H3 id="contribute">Contribute</H3>
    <HFlex sx={{alignItems: 'flex-start'}}>
      <VFlex sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
    <T>To keep {siteName} free and fund further development, you can contribute to this address:</T>
        <HFlex sx={{width: 1, justifyContent: 'space-between'}}>
          <T gutterBottom={false}>
            <Box sx={{display: { xs: 'none', md: 'inline-block'}}}>SURFFLOWSDVKMM4DS5G7JNC4C74HT6STX3A4CWDC64SILS3ZONK4SXQMCU</Box>
            <Box sx={{display: { xs: 'inline-block', md: 'none'}}}>{snipAddress('SURFFLOWSDVKMM4DS5G7JNC4C74HT6STX3A4CWDC64SILS3ZONK4SXQMCU', 9)}</Box>
          <CopyButton noLabel={true} value="SURFFLOWSDVKMM4DS5G7JNC4C74HT6STX3A4CWDC64SILS3ZONK4SXQMCU"/></T>
          <Button variant="outlined" size="small" sx={{display: { xs: 'inline-block', md: 'none' } }} onClick={() => window.open('algorand://SURFFLOWSDVKMM4DS5G7JNC4C74HT6STX3A4CWDC64SILS3ZONK4SXQMCU')}>DONATE</Button>
        </HFlex>
        <T>Please include a note with what feature would be most useful to you.</T>
        <T>We are on Twitter as <Link href="https://twitter.com/d13_co" target="_blank">D13_co</Link> and <Link href="https://twitter.com/algo_surf" target="_blank">algo_surf</Link>.</T>
      </VFlex>
      <Box sx={{ display: { xs: 'none', md: 'block'}}}><img src={donateImage} width={128} height={128} /></Box>
    </HFlex>
  </>
}

function Fourohfour() {
  return <VFlex sx={{flexGrow: 1}}>404. Page not found.</VFlex>
}


export default function App() {
  return <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" exact={true} element={<AppAddress />} />
          <Route path="/address/:address" element={<AppAddress />} />
          <Route path="/address-book" element={<AddressBook />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="*" element={<Fourohfour />} />
        </Route>
      </Routes>
    </BrowserRouter>
}
