import { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

export default function({ value, copyIcon, label='COPY', noLabel, ...props }) {
  let initIcon = noLabel || copyIcon ? <ContentCopyIcon fontSize="inherit"/> : null;
  const [showLabel, setShowLabel] = useState(noLabel ? '' : label);
  const [color, setColor] = useState('info');
  const [disabled, setDisabled] = useState(false);
  const [icon, setIcon] = useState(initIcon);

  useEffect(() => {
    setShowLabel(label);
  }, [label]);

  const reset = () => { setColor('info'); setShowLabel(noLabel ? '' : 'COPY'); setDisabled(false); setIcon(initIcon); }

  const doCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(value);
      setShowLabel(noLabel ? '' : 'COPIED');
      setIcon(<CheckIcon fontSize="inherit"/>);
      setColor('success');
      setDisabled(true);
    } catch(e) {
      setShowLabel(noLabel ? '' : 'ERROR');
      setColor('error');
    }
    setTimeout(reset, 2000);
  }, [value]);

  return noLabel ? <IconButton {...props} color="inherit" size="small" disabled={disabled} onClick={doCopy}>{icon}</IconButton> : <Button {...props} startIcon={icon} disabled={disabled} onClick={doCopy}>{showLabel}</Button>
}
